import jsCookie from 'js-cookie';
import * as React from 'react';

import { APPLICATION_VERSION, IS_PRODUCTION } from '../constants';
import { CookiePolicyEnum } from '../enums';
import createContext from './create-context';

type ProviderProps = {
  children: React.ReactNode;
};

interface CookiePolicy {
  showCookieBanner: boolean;
  cookiePolicy: CookiePolicyEnum;
}

type ProviderValue = {
  cookiePolicy: CookiePolicy;
  setCookiePolicy: (newCookiePolicy: CookiePolicyEnum) => void;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

function getCookiePolicyKey() {
  return `cookie-policy-${APPLICATION_VERSION}`;
}

export function CookiePolicyProvider(props: ProviderProps) {
  let { children } = props;
  let [cookiePolicy, setCookiePolicyState] = React.useState({
    showCookieBanner: true,
    cookiePolicy: CookiePolicyEnum.Minimal,
  });

  React.useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        let policy = jsCookie.get(getCookiePolicyKey());
        if (typeof policy === 'string') {
          setCookiePolicyState({
            showCookieBanner: false,
            cookiePolicy: policy === 'minimal' ? CookiePolicyEnum.Minimal : CookiePolicyEnum.All,
          });
        }
      }
    } catch (err) {
      // do nothing
    }
  }, []);

  const setCookiePolicy = (newPolicy: CookiePolicyEnum) => {
    setCookiePolicyState({
      showCookieBanner: false,
      cookiePolicy: newPolicy,
    });

    jsCookie.set(getCookiePolicyKey(), newPolicy, {
      // Expire in 2 days
      expires: 2,
      secure: IS_PRODUCTION,
      sameSite: IS_PRODUCTION ? 'None' : undefined,
    });
  };

  return (
    <ReactProvider
      value={{
        cookiePolicy,
        setCookiePolicy,
      }}
    >
      {children}
    </ReactProvider>
  );
}

export const useCookiePolicy = useContext;
export const CookiePolicyConsumer = ReactConsumer;
