import splitbee from '@splitbee/web';
import React from 'react';

interface Props {}

export const Splitbee: React.FC<Props> = () => {
  React.useEffect(() => {
    splitbee.init({
      token: 'HFZG8MV15CFY',
      scriptUrl: '/bee.js',
      apiUrl: '/_hive',
    });
  }, []);

  // Should never really render anything...
  return null;
};
