import '../styles/style.scss';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import React from 'react';

import { CookieBanner } from '../components/analytics/cookie-banner';
import { Splitbee } from '../components/analytics/splitbee';
import { CookiePolicyConsumer, CookiePolicyProvider } from '../contexts/cookie-context';
import { CookiePolicyEnum } from '../enums';
import sentry from '../utils/sentry';

export const { Sentry, captureException } = sentry();

const theme = extendTheme({
  colors: {
    brand: {
      300: '#a18f60',
    },
  },
});

const App: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props;

  return (
    <ChakraProvider theme={theme}>
      <CookiePolicyProvider>
        <CookiePolicyConsumer>
          {(value) => {
            if (value.cookiePolicy.cookiePolicy !== CookiePolicyEnum.Minimal) {
              return <Splitbee />;
            } else {
              return null;
            }
          }}
        </CookiePolicyConsumer>
        <Component {...pageProps} />
        <CookieBanner />
      </CookiePolicyProvider>
    </ChakraProvider>
  );
};

export default App;
