import NextLink from 'next/link';
import React from 'react';

import { useCookiePolicy } from '../../contexts/cookie-context';
import { CookiePolicyEnum } from '../../enums';

export const CookieBanner: React.FC = () => {
  let { setCookiePolicy, cookiePolicy } = useCookiePolicy();

  if (cookiePolicy.showCookieBanner) {
    return (
      <div className="fixed flex w-full justify-center px-4" style={{ bottom: 5 }}>
        <div className="p-2 rounded bg-white border border-gray-400 flex items-center text-gray-800">
          <span>
            This website uses{' '}
            <NextLink href="/policies/cookie-policy" className="underline" passHref>
              cookies
            </NextLink>
          </span>
          <button
            className="ml-2 text-gray-700 underline rounded"
            onClick={() => {
              setCookiePolicy(CookiePolicyEnum.Minimal);
            }}
          >
            Minimal
          </button>
          <button
            className="px-2 py-1 bg-green-400 text-white uppercase font-bold text-sm rounded ml-4"
            onClick={() => {
              setCookiePolicy(CookiePolicyEnum.All);
            }}
          >
            Agree
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
